var fixedheader = {
    init: function(){

        var screenwidth = $(window).outerWidth()
        var headerheight = $('header .navbar').outerHeight();
		var navtopHeight = $('.p-nav__top').outerHeight();
		// var totalHeaderheight = -(headerheight - navtopHeight);
		
		// $('main').css('margin-top',headerheight);   

		if (screenwidth <= 999) {
			$('main').css('margin-top',headerheight);
		} 
		else { 
			$('main').css('margin-top','inherit');
		}

/* 		if (screenwidth >= 1000) {        
			$(window).bind('scroll', function () {
				var num = 450;
				if ($(window).scrollTop() > num) {
					$('header').addClass('shrink');
					// $('header').css('margin-top',totalHeaderheight);
				} else {
					$('header').removeClass('shrink');
					// $('header').css('margin-top','inherit');
				}
			});
		} 
		else { 
			$('header').removeClass('shrink');
		}
 */
    }
}