$(document).ready(function(){
    // console.log('document ready - it works');
})
window.onload = function(){
    // console.log("window onload - it works")
    accordion.init();
    backtotop.init();
    // collapse.init();
    // dropdown.init();
    // fixedheader.init();
    // search.init();  
    // toast.init(); 
    var screenheight = $(window).outerHeight()
    var headerHeight = $('header').outerHeight();
    var footerHeight = $('footer').outerHeight();
    var mainHeight = screenheight - headerHeight - footerHeight;
    $('main').css('min-height',mainHeight);

    // var totalHeaderheight = -(headerheight - navtopHeight);


}
$(window).resize(function() {
// $( window ).on( "orientationchange", function( event ) {
    // fixedheader.init();
  });